import {
  BillingRequestResource,
  ConsentType,
} from "@gocardless/api/dashboard/types";
import { Box, ColorPreset, useTheme, Text } from "@gocardless/flux-react";
import { Scheme } from "src/common/scheme";
import { getDisplayedConsentInfo } from "src/common/scheme-translations/DisplayedConsentInformation/getDisplayedConsentInfo";

interface StandingDebitExplainerProps {
  billingRequest: BillingRequestResource;
}
export const StandingDebitExplainer = ({
  billingRequest,
}: StandingDebitExplainerProps) => {
  const { theme } = useTheme();

  if (!billingRequest.payment_request) {
    return null;
  }

  const { toolTipContent } = getDisplayedConsentInfo(
    Scheme.Ach,
    ConsentType.Standing
  );

  return (
    <Box
      style={{
        borderRadius: `0 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
        border: "1px solid",
        borderTop: "none",
        borderColor: theme.color(ColorPreset.BorderOnLight_04),
        background: theme.color(ColorPreset.BackgroundLight_01),
        padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
      }}
    >
      <Text size={1} color={ColorPreset.TextOnLight_01}>
        {toolTipContent}
      </Text>
    </Box>
  );
};
