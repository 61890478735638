import { Box, Color, XYGrid } from "@gocardless/flux-react";

import BillingRequestDescription, {
  BillingRequestDescriptionProps,
} from "../index";
import { EducationWrapper } from "../EducationWrapper";

const BillingRequestDescriptionPanel = ({
  billingRequest,
  billingRequestFlow,
  plan,
  instalmentTemplate,
  paylink,
  page,
}: BillingRequestDescriptionProps) => {
  return (
    <Box
      bg={Color.Greystone_100}
      borderRadius={1}
      borderWidth={1}
      borderColor={Color.Greystone_100}
    >
      <Box gutterH={[1, 2]} gutterV={[1, 1.5]}>
        <XYGrid rowGap={1.5}>
          <BillingRequestDescription
            billingRequest={billingRequest}
            billingRequestFlow={billingRequestFlow}
            plan={plan}
            instalmentTemplate={instalmentTemplate}
            paylink={paylink}
            page={page}
          />
        </XYGrid>
      </Box>

      <EducationWrapper paylink={paylink} billingRequest={billingRequest} />
    </Box>
  );
};

export default BillingRequestDescriptionPanel;
