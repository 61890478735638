import { BillingRequestResource } from "@gocardless/api/dashboard/types";
import {
  Accordion,
  AccordionItem,
  AccordionMode,
  AccordionSize,
  AccordionVariant,
  AlignItems,
  Box,
  Color,
  ColorPreset,
  FontWeight,
  Glyph,
  Icon,
  JustifyContent,
  LI,
  Text,
  TypePreset,
  UL,
  XYGrid,
  useTheme,
} from "@gocardless/flux-react";
import Image from "next/image";
import { Trans } from "@lingui/macro";
import { useSegment } from "src/shared/Segment/useSegment";
import { TrackingEvents } from "src/common/trackingEvents";

import { usePopularBanksIcons } from "./usePopularBanksIcons";

interface InstantBankPaymentProps {
  billingRequest: BillingRequestResource;
}
export const InstantBankPayment = ({
  billingRequest,
}: InstantBankPaymentProps) => {
  const { theme } = useTheme();
  const { icons, total } = usePopularBanksIcons(billingRequest);
  const imgSize = Number(theme.spacing(1.5).replace("px", ""));

  const { sendEvent } = useSegment();

  return (
    <Accordion
      minimumSelected={0}
      toggleVisibility="visible"
      mode={AccordionMode.Single}
      size={AccordionSize.Sm}
      variant={AccordionVariant.SolidOnLight}
      css={{
        borderRadius: `0 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
        border: "1px solid",
        borderTop: "none",
        borderColor: theme.color(ColorPreset.BorderOnLight_04),
        background: theme.color(ColorPreset.BackgroundLight_01),
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      }}
      onChange={(items) => {
        if (items.length > 0)
          sendEvent(
            TrackingEvents.DESCRIPTION_PANEL_EDUCATION_ACCORDION_OPEN_CLICKED
          );
      }}
    >
      <AccordionItem
        id="ibp"
        title={
          <>
            <XYGrid
              templateColumns={["1fr", null, "1fr auto"]}
              width="100%"
              rowGap={0.75}
            >
              <Box
                layout="flex"
                alignItems={AlignItems.Center}
                css={{ gap: theme.spacing(0.5) }}
              >
                <Icon name={Glyph.Fast} size="16px" />
                <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
                  <Trans id="billing-request-description.payment-description.instant-payment">
                    Instant bank pay
                  </Trans>
                </Text>
              </Box>

              <Box layout="flex" alignItems={AlignItems.Center}>
                {icons.map((img) =>
                  img ? (
                    <Image
                      key={img}
                      src={img}
                      width={imgSize}
                      height={imgSize}
                      alt={img}
                    />
                  ) : null
                )}
                {total > 0 ? (
                  <Box
                    layout="flex"
                    alignItems={AlignItems.Center}
                    justifyContent={JustifyContent.Center}
                    width={imgSize}
                    height={imgSize}
                    bg={Color.Greystone_200}
                    borderRadius={2}
                  >
                    <Text weight={FontWeight.SemiBold} css={{ fontSize: 9 }}>
                      {total}+
                    </Text>
                  </Box>
                ) : null}
              </Box>
            </XYGrid>
          </>
        }
      >
        <UL preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_01}>
          <LI>
            <Trans id="billing-request-description.payment-description.instant-payment-info-li-0">
              Quick, safe and secure
            </Trans>
          </LI>
          <LI>
            <Trans id="billing-request-description.payment-description.instant-payment-info-li-1">
              No card needed
            </Trans>
          </LI>
          <LI>
            <Trans id="billing-request-description.payment-description.instant-payment-info-li-2">
              Confirm payment using your online banking or mobile app
            </Trans>
          </LI>
        </UL>
      </AccordionItem>
    </Accordion>
  );
};
