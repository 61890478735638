import {
  ActionType,
  BillingRequestResource,
} from "@gocardless/api/dashboard/types";
import { useInstitutionListForBillingRequest } from "@gocardless/api/dashboard/institution";
import { useMemo } from "react";
import { CountryCodes } from "src/common/country";
import { isUsingCompanyName } from "src/common/utils";
import {
  BanksByCountry,
  TopPopularBusinessBanks,
  TopPopularPersonalBanks,
} from "src/common/topPopularBanks";

import { findAction } from "../../Router";

export const usePopularBanksIcons = (
  billingRequest: BillingRequestResource
) => {
  const countryCode = useMemo(() => {
    const collectCustomerAction = findAction(
      billingRequest,
      ActionType.CollectCustomerDetails,
      null
    );
    const customerDetailCountryCode = collectCustomerAction
      ?.collect_customer_details?.default_country_code as CountryCodes;

    return [CountryCodes.GB, CountryCodes.DE, CountryCodes.FR].includes(
      customerDetailCountryCode
    )
      ? customerDetailCountryCode
      : CountryCodes.GB;
  }, [billingRequest]);

  const popularBanks = isUsingCompanyName(billingRequest)
    ? TopPopularBusinessBanks
    : TopPopularPersonalBanks;

  const { data } = useInstitutionListForBillingRequest(
    billingRequest.id ?? "",
    {
      country_code: countryCode,
    },
    !!countryCode && !!billingRequest.id
  );

  const institutions = data?.institutions;

  const icons = popularBanks[countryCode as keyof BanksByCountry]
    .slice(0, 6)
    .map(
      (institution, idx) =>
        institutions?.find((entry) => entry.id === institution)?.logo_url ||
        institutions?.[idx]?.logo_url
    );

  return {
    icons,
    total: (Math.ceil((data?.institutions?.length ?? 0 - 6) / 10) - 1) * 10,
  };
};
